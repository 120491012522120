<template>
  <div>
    <navigation msg="身份切换"></navigation>
    <div class="flex">
      <img  src="../../assets/niu.png" alt="">
      <div class="status">您当前的身份是“个人”</div>
      <div class="change" >
        <a  href="https://copm.luobolieshou.com/">切换为“雇主”身份</a> 
      </div>
      <div class="goback" @click=goback()>返回</div>
    </div>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index"
export default {
  components:{
    navigation
  },
  methods:{
    goback(){
      this.$router.push("./user")
    }
  }
}
</script>
<style scoped>
.flex{
  text-align: center;
}
.flex img{
  width:6.3rem;
  height:3.7rem;
  margin-top:2rem;
}
.status{
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #F55D1C;
  margin-top:1rem;
}
.change{
  width: 7.02rem;
  height: 0.97rem;
  background: linear-gradient(90deg, #FF4800 0%, #FC9E46 100%);
  border-radius: 0.16rem;
  margin:0 auto;
  line-height: 0.97rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-top:0.88rem;
}
.change a{
  color:#fff;
}
.goback{
  width: 7.02rem;
height: 0.97rem;
line-height: 0.97rem;
background: #F7F7F7;
border-radius: 0.16rem;
border: 0.02rem solid #FF5817;
color:#FF5817;
margin:0 auto;
margin-top:0.2rem;
}
</style>